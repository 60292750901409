import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppComponent } from './app.component';


import { TituladoModule } from './tituladoform/tituladoform.module';
import { AppRoutingModule } from './app-routing.module';

import { InterceptorService } from './service/interceptor.service';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    //Routing
    AppRoutingModule,


    //modulos custom
    TituladoModule,

  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:InterceptorService,
      multi:true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
