import { Component,  OnInit } from '@angular/core';
import {  UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Comuna } from '../../../interfaces/comuna.interface';
import { Pais } from '../../../interfaces/pais.interface';

import { TituladoService } from 'src/app/tituladoform/service/Titulado/titulado.service';
import { Validacion } from 'src/app/tituladoform/validaciones/validacion';
import { AmbitoLaboral } from '../../interfaces/formTitulado/ambitoLaboral';
import { TipoContrato } from '../../interfaces/formTitulado/contratoTipo';
import { Formulario } from '../../interfaces/formTitulado/formulario';
import { Titulado } from '../../interfaces/formTitulado/titulado';

import { TiempoOferta } from '../../interfaces/formTitulado/tiempo_oferta-interface';
import { TiempoSinTrabajo } from '../../interfaces/formTitulado/tiempo_sin_trabajo-interface';



import { Genero } from '../../../interfaces/genero.interface';


@Component({
  selector: 'app-titulado',
  templateUrl: './titulado.component.html',
  styleUrls: ['./titulado.component.css']
})
export class TituladoComponent implements OnInit {

  //@Output() comuna: Comuna[]| undefined;
  //comunas:Comuna[]=[];

  formulario: Formulario = <Formulario>{};
  titulado: Titulado=<Titulado>{};

  mensajeRut:string="Ingrese su RUT";
  mensajeError="";
  mensajeCorrecto="";
  mostrarContenido=false;

  //correo variables.
  botones = false
  cuadroInforme=false;
  cuadroActualiza=false;
  cuadroBoton=false;
  enviandoCorreo=false;
  mensajeCorreoBol=false;
  mensajeCorreo = ''
  //empresa variables
  mostrarEmpresa=false;
  ofertasEmpleo=false;
  validatorEmpleador=false;

  //pais_variables
  mostrarPaises=false;
  mostrarComunaDomicilio=true;


  paises :Pais[]=[];

  comunas :Comuna[]=[];
  contratosTipo:TipoContrato[]=[];
  generos:Genero[]=[];
  ambitos:AmbitoLaboral[]=[];
  tiempo_ofertas: TiempoOferta[]=[]
  tiempo_sin_trabajos :TiempoSinTrabajo[]=[]



  tituladoForm:UntypedFormGroup = this._builder.group({
    rut:[''],
    dv:[''],
    nombres:{value:'', disabled:true},
    paterno:{value:'', disabled:true},
    materno:{value:'', disabled:true},
    direccion:['', [Validators.required,Validators.maxLength(255)] ],
    numeracion:['', [Validators.required,Validators.maxLength(20)] ],
    comuna:['',Validators.required],
    correo:['', [Validators.email,Validators.required]],
    telefono_fijo:['', [Validators.maxLength(8),Validacion.numerico]],
    telefono_movil:['', [Validators.required, Validators.maxLength(8),Validacion.numerico]],
    correo_institucional:['', [Validators.email]],
    token:[''],
    trabajas:['',[Validators.required]],
    empresa:['', Validators.maxLength(255)],
    cargo:['', Validators.maxLength(255)],
    direccion_empresa:['', [Validators.maxLength(255)]],
    numeracion_empresa:['', [Validators.maxLength(15)] ],
    comuna_empresa:[''],

    otro_domicilio:[''],
    otro_laboral:[''],
    
    comentarios:['', Validators.maxLength(255)],
    empleador_nombre:[''],
    empleador_telefono:['',[Validators.maxLength(9),Validacion.numerico]],
    empleador_correo:['', [Validators.email]],
    ofertas_empleo:['',[Validators.required]],
    tiempo_ofertas:[''],
    ambito_laboral:this._builder.array([]),
    contrato_tipo:[''],
    genero:['',[Validators.required]],

    taller_empleabilidad:[''],
    tiempo_sin_trabajo:[''],
    empleo_relacion_carrera:[''],
    reside_otro_pais:['',[Validators.required]],
    pais_domicilio_extranjero:[''],


  });
  rutForm:UntypedFormGroup = this._builder.group({
    rut:['', Validators.required],
  });


  public get ambitoLaboral(){
    return this.tituladoForm.get('ambito_laboral') as UntypedFormArray
  }

  constructor(
    private _builder: UntypedFormBuilder,
    private _tituladoService: TituladoService,
  ) {}
  
  closeResult = '';

  ngOnInit(): void {
    this.getGeneros();
    this.getComunas();
    this.getPaises();
    this.getAmbitosLaborales();
    this.getContratosTipo();
    this.getTiempoSinTrabajo();
    this.getTiemposOferta();
    this.enviandoCorreo=false;
    this.mensajeCorreoBol=false;
    
    this.botones = false;


    this.tituladoForm.controls['trabajas'].valueChanges.subscribe(
      value=>{
        
        if(value=='true'){//es true o false??

          this.tituladoForm.controls['empresa'].setValidators(Validators.required)
          this.tituladoForm.controls['cargo'].setValidators(Validators.required)
          this.tituladoForm.controls['direccion_empresa'].setValidators(Validators.required)
          this.tituladoForm.controls['numeracion_empresa'].setValidators(Validators.required)
          this.tituladoForm.controls['comuna_empresa'].setValidators(Validators.required)
          this.tituladoForm.controls['empleador_nombre'].setValidators(Validators.required)
          this.tituladoForm.controls['empleador_correo'].setValidators(Validators.required)
          this.tituladoForm.controls['contrato_tipo'].setValidators(Validators.required)
          this.tituladoForm.controls['empleo_relacion_carrera'].setValidators(Validators.required)


          this.tituladoForm.controls['ofertas_empleo'].removeValidators(Validators.required)
          this.tituladoForm.controls['tiempo_sin_trabajo'].removeValidators(Validators.required)

          this.tituladoForm.controls['ofertas_empleo'].setValue('');
          this.tituladoForm.controls['tiempo_sin_trabajo'].setValue('');
          this.tituladoForm.controls['tiempo_ofertas'].setValue('');
          this.tituladoForm.controls['taller_empleabilidad'].setValue('');


          this.mostrarEmpresa=true;
    
        }else{
          
          this.tituladoForm.controls['empleador_telefono'].setValue('');
          this.tituladoForm.controls['numeracion_empresa'].setValue('');

          this.tituladoForm.controls['ofertas_empleo'].setValidators(Validators.required)
          this.tituladoForm.controls['tiempo_sin_trabajo'].setValidators(Validators.required)



          this.tituladoForm.controls['empresa'].removeValidators(Validators.required)
          this.tituladoForm.controls['cargo'].removeValidators(Validators.required)
          this.tituladoForm.controls['direccion_empresa'].removeValidators(Validators.required)
          this.tituladoForm.controls['numeracion_empresa'].removeValidators(Validators.required)
          this.tituladoForm.controls['comuna_empresa'].removeValidators(Validators.required)
          this.tituladoForm.controls['empleador_nombre'].removeValidators(Validators.required)
          this.tituladoForm.controls['empleador_correo'].removeValidators(Validators.required)
          this.tituladoForm.controls['contrato_tipo'].removeValidators(Validators.required)
          this.tituladoForm.controls['empleo_relacion_carrera'].removeValidators(Validators.required)

          this.tituladoForm.controls['empresa'].setValue('');
          this.tituladoForm.controls['cargo'].setValue('');
          this.tituladoForm.controls['direccion_empresa'].setValue('');
          this.tituladoForm.controls['numeracion_empresa'].setValue('');
          this.tituladoForm.controls['comuna_empresa'].setValue('');
          this.tituladoForm.controls['empleador_nombre'].setValue('');
          this.tituladoForm.controls['empleador_correo'].setValue('');
          this.tituladoForm.controls['contrato_tipo'].setValue('');
          this.tituladoForm.controls['empleo_relacion_carrera'].setValue('');

          


          // this.tituladoForm.controls['empresa'].clearValidators()
          // this.tituladoForm.controls['cargo'].clearValidators()
          // this.tituladoForm.controls['direccion_empresa'].clearValidators()
          // this.tituladoForm.controls['numeracion_empresa'].clearValidators()
          // this.tituladoForm.controls['comuna_empresa'].clearValidators()
          
          // this.tituladoForm.controls['empleador_nombre'].clearValidators()
          // this.tituladoForm.controls['empleador_correo'].clearValidators()
          // this.tituladoForm.controls['contrato_tipo'].clearValidators()
          // this.tituladoForm.controls['empleo_relacion_carrera'].clearValidators()





          this.mostrarEmpresa=false;
    
        }

        this.tituladoForm.controls['empleador_correo'].updateValueAndValidity()
      }
    )
    this.tituladoForm.controls['reside_otro_pais'].valueChanges.subscribe(
      value=>{
        
        if(value=='true'){//es true o false??

          this.mostrarPaises=true;
          this.mostrarComunaDomicilio = false;

          //this.tituladoForm.controls['comuna'].setValue('');
          this.tituladoForm.addControl('pais_domicilio_extranjero', new UntypedFormControl('',Validators.required));


          this.tituladoForm.controls['comuna'].removeValidators(Validators.required);
          this.tituladoForm.controls['comuna'].setValue('');
          
          //console.log(this.tituladoForm.controls['comuna'].validator?.name)
          //console.log(this.tituladoForm.controls['comuna'].valid)
          this.tituladoForm.removeControl('comuna');


          this.tituladoForm.addControl('comuna', new UntypedFormControl(''));

    
        }else{

          //this.tituladoForm.addControl('comuna', new FormControl('',Validators.required));
          //this.tituladoForm.controls['comuna'].setValidators(Validators.required);
          
          this.tituladoForm.removeControl('pais_domicilio_extranjero');

          this.tituladoForm.controls['comuna'].addValidators(Validators.required)

          //console.log(this.tituladoForm.controls['comuna'].validator?.name)
      
          this.mostrarComunaDomicilio = true;

          this.mostrarPaises=false;
    
        }

      }
    )
    this.tituladoForm.controls['ofertas_empleo'].valueChanges.subscribe(  
      value=>{
        if(value=='true'){//es true o false??
          this.ofertasEmpleo=true;

          this.tituladoForm.controls['comentarios'].setValidators(Validators.maxLength(230))
          this.tituladoForm.controls['tiempo_ofertas'].setValidators(Validators.required)
          this.tituladoForm.controls['taller_empleabilidad'].setValidators(Validators.required)




        }else{
          this.ofertasEmpleo=false;

          this.tituladoForm.controls['tiempo_ofertas'].removeValidators(Validators.required)
          this.tituladoForm.controls['taller_empleabilidad'].removeValidators(Validators.required)
          this.tituladoForm.controls['tiempo_ofertas'].setValue('');
          this.tituladoForm.controls['taller_empleabilidad'].setValue('');
          this.tituladoForm.controls['comentarios'].removeValidators(Validators.maxLength(230));

        }

      }
    )
  }
  findInvalidControl(){
    const invalidList = [];
    const controls = this.tituladoForm.controls
    for(const name in controls ){
      if(controls[name].invalid){

        invalidList.push(name)

      }

    }
    return console.log(invalidList)
  }
  getPaises(){
    this._tituladoService.getPaises().subscribe(
      resp=>{
        this.paises=resp
      },
      errors=>{
  
        this.paises=[]
      }

    )
  }
  mostrarContenidoForm(){
    this.mostrarContenido = true;
    this.botones = false;
  }
  enviarInforme(){
    this.enviandoCorreo = true;
    this.cuadroBoton = false;
    //console.log(this.tituladoForm.controls['rut'].value)
    this._tituladoService.getInformeFormulario(this.tituladoForm.controls['rut'].value).subscribe(
      resp=>{
        this.enviandoCorreo = false;
        this.mensajeCorreoBol =true; 
        this.mensajeCorreo = 'Hemos enviado un informe al correo '+ this.formulario.email_actualizado
        setTimeout(() => {
          //window.location.reload();

          //this.rutForm.controls['rut'].setValue('');
          this.mensajeCorreo = '';
          this.mensajeCorreoBol = false;
          this.cuadroInforme=false;
          //this.cuadroActualiza=false;

        }, 6500);
      }
    )
    
  }
  seleccciorAmbito(index:number){
    if( this.ambitoLaboral.controls[index].get('select')?.value ){
      this.ambitoLaboral.controls[index].get('select')?.setValue(false);
    }else{
      this.ambitoLaboral.controls[index].get('select')?.setValue(true);
    }
  }
  getAmbitosLaborales(){
    this._tituladoService.getAmbitoLaboral().subscribe(
      resp=>{
        this.ambitos=resp

        for (let index = 0; index < this.ambitos.length; index++) {
          const ambito = this.ambitos[index];
          this.ambitoLaboral.push(
            this._builder.group(
              {
                nombre:[ambito.nom_ambito],
                id:[ambito.id],
                select:[false]
              }
            )
          )
          
        }

      },
      errors=>{
  
        this.ambitos=[]
      }

    )
  }
  getContratosTipo(){
    this._tituladoService.getContratoTipo().subscribe(
      resp=>{
        this.contratosTipo=resp
      },
      errors=>{
  
        this.contratosTipo=[]
      }

    )
  }
  getGeneros(){
    this._tituladoService.getGenero().subscribe(
      resp=>{
        this.generos=resp
      },
      errors=>{
  
        this.generos=[]
      }

    )
  }
  getComunas(){

    this._tituladoService.getComunas().subscribe(
      resp=>{
        this.comunas=resp
      },
      errors=>{
  
        this.comunas=[]
      }

    )
  
  }
  getTiemposOferta(){

    this._tituladoService.getTiempoOfertas().subscribe(
      resp=>{
        this.tiempo_ofertas=resp
      },
      errors=>{
  
        this.tiempo_ofertas=[]
      }

    )
  
  }
  getTiempoSinTrabajo(){

    this._tituladoService.getTiempoSinTrabajo().subscribe(
      resp=>{
        this.tiempo_sin_trabajos=resp
      },
      errors=>{
  
        this.tiempo_sin_trabajos=[]
      }

    )
  
  }
  validarNumero(nombre:string){
    var control = this.tituladoForm.controls[nombre].value.trim()

    if  (!isNaN(parseInt(control))){
      return 
    }
    
    this.tituladoForm.controls[nombre].setErrors({

    });


  }
  buscarRut(){

    //this.mensajeCorrecto="";
    this._tituladoService.getTituladoForm(this.rutForm.value).subscribe(
      resp =>{
        
        this.formulario = resp.formulario
        
        this.titulado = resp.titulado
        //pregunto si el formulario es true para mostrar los botones de enviar formulario o actualizar datos
        if(resp.titulado.formulario){
          this.botones = true;
          this.cuadroInforme =true;
          this.cuadroBoton = true;
          this.cuadroActualiza =true;

        }else{

          this.botones = false
          this.mostrarContenido = true

          this.enviandoCorreo=false;
          this.mensajeCorreoBol=false;
          this.mensajeCorreo = ''

        }

        if(resp.titulado.nombre_social){
          this.tituladoForm.controls['nombres'].setValue(resp.titulado.nombre_social);
        }
        else{
          this.tituladoForm.controls['nombres'].setValue(resp.titulado.nombres);
        }
        
        this.tituladoForm.controls['rut'].setValue(resp.titulado.rut);
        this.tituladoForm.controls['dv'].setValue(resp.titulado.dig_ver);
        this.tituladoForm.controls['paterno'].setValue(resp.titulado.paterno);
        this.tituladoForm.controls['materno'].setValue(resp.titulado.materno);
        this.tituladoForm.controls['direccion'].setValue('');
        this.tituladoForm.controls['telefono_fijo'].setValue('');
        this.tituladoForm.controls['empleador_telefono'].setValue('');


        this.ofertasEmpleo = false;

        

      },
      errors=>{
        this.mensajeRut = errors
      }

    );
    return
    

    
  }
  
  validador(nombre:string){

    if( this.tituladoForm.controls[nombre].valid || !this.tituladoForm.touched ){
      return "";
    }

    if(this.tituladoForm.controls[nombre].errors?.required){
      return "Este campo es obligatorio."
    }

    if(this.tituladoForm.controls[nombre].errors?.email){
      return "Debe ser un email válido."
    }
    
    if(this.tituladoForm.controls[nombre].errors?.minLength){
      return "No puede tener menos de "+this.tituladoForm.controls[nombre].errors?.minLength.requiredLength+ " caractéres."
    }
    
    if(this.tituladoForm.controls[nombre].errors?.maxlength){
    
      return "No puede superar los "+this.tituladoForm.controls[nombre].errors?.maxlength.requiredLength+" caractéres.";
    }
    if(this.tituladoForm.controls[nombre].errors?.numerico){

      return "Debe ser numérico";
      
    }
 
    return ""
  }
  guardarFormulario(){


    //console.log(this.tituladoForm.value)
    //console.log(this.findInvalidControl())
    //return 

    this.resert();


    if(this.tituladoForm.invalid){
      this.tituladoForm.markAllAsTouched();
      return;
    }

    let idAmbitoLaboral=[]
    for (let index = 0; index < this.ambitoLaboral.length; index++) {
      if(this.ambitoLaboral.controls[index].get('select')?.value){
        idAmbitoLaboral.push(this.ambitoLaboral.controls[index].get('id')?.value)
      }
    }
   
    let formulario = {...this.tituladoForm.value}
    formulario.ambito_laboral=idAmbitoLaboral;

    this._tituladoService.putFormulario(formulario).subscribe(
        resp=>{

          this.tituladoForm.reset();
          this.ambitoLaboral.clear();
          this.getAmbitosLaborales();
          this.mensajeCorrecto="Formulario enviado, gracias por su participación.";
          this.mostrarContenido=false;
          this.rutForm.controls['rut'].setValue('');

          this.botones = false

          this.enviandoCorreo=false;
          this.mensajeCorreoBol=false;
          this.mensajeCorreo = ''


        },
        errors=>{
        
          if(errors.error.mensajes){

            var text="";
            var error = errors.error.mensajes;

            if(Array.isArray(error)){
              for (let i = 0; i < error.length; i++) {

                if(i==0){
                  text=error[i];
                }else{
                  text+="<br>"+error[i];
                }
                
              }
            }else{
              text=errors.error.mensajes;
            }

            this.mensajeError = text;
          }else{
            this.mensajeError = "No se pudo obtener información desde el servidor, intente mas tarde"
          }
        }
      ) 



  }
  resert(){

    this.mensajeRut="";
    this.mensajeError="";
    this.mensajeCorrecto="";

  }
  checkRut():boolean {
    this.resert();
    var rut = this.rutForm.controls['rut'].value;
    const newRut = rut.replace(/\./g,'').replace(/\-/g, '').trim();
        const lastDigit = newRut.substr(-1, 1);
        const rutDigit = newRut.substr(0, newRut.length-1)
        let format = '';
        for (let i = rutDigit.length; i > 0; i--) {
          const e = rutDigit.charAt(i-1);
          format = e.concat(format);
          if (i % 3 === 0){
            format = '.'.concat(format);
          }
        }
    this.rutForm.controls['rut'].setValue(format.concat('-').concat(lastDigit))

    this.mensajeRut = "";
    // Despejar Puntos
    var valor = rut.replace('.','').replace('.','').replace('.','');
    // Despejar Guión
    valor = valor.replace('-','');
    
    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv:string = valor.slice(-1).toUpperCase();
    
    // Formatear RUN
    rut = cuerpo + '-'+ dv
    
    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { this.mensajeRut = "RUT Incompleto"; return false;}
    
    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;
    
    // Para cada dígito del Cuerpo
    for(var i=1;i<=cuerpo.length;i++) {
    
        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * parseInt( valor.charAt( cuerpo.length - i) );
        
        // Sumar al Contador General
        suma = suma + index;
        
        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
  
    }
    
    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);
    
    // Casos Especiales (0 y K)
    dv = dv == 'K'?"10":dv;
    dv = dv == "0"?"11":dv;
    
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado+'' != dv) { this.mensajeRut = "RUT Inválido"; return false; }
    
    // Si todo sale bien, eliminar errores (decretar que es válido)
    this.mensajeRut = "";
    return true;
  }




  

}
