import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {Routes, RouterModule} from '@angular/router'
import { TituladoComponent } from './componentes/titulado/titulado.component';





const routes:Routes=[
  {
    path:'',
    component:TituladoComponent
    // children:[
    //   {
    //     path:'titulado',
    //     component: TituladoComponent
    //   },
    //   {
    //     path:'**',
    //     redirectTo:'titulado'

    //   }
    // ]
  },
]



@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports:[
    RouterModule
  ]
})
export class TituladoRoutingModule { }
