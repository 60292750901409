import { UntypedFormControl, ValidationErrors } from '@angular/forms';



export class Validacion {

    static numerico(control: UntypedFormControl): ValidationErrors|null {



        if(control.value?.length==0) return null;



        let nro = parseInt(control.value);

        if (isNaN(nro)){

            return { numerico: true };
        }
        else{ 
            if( nro.toString().trim().length != control.value.trim().length ){
                return { numerico: true };
            }    

            return null;

        }
    }


}