import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Comuna } from 'src/app/interfaces/comuna.interface';
import { Genero } from 'src/app/interfaces/genero.interface';
import { Pais } from 'src/app/interfaces/pais.interface';



import { Formulario } from 'src/app/tituladoform/interfaces/formTitulado/formulario';
import { FormPut } from 'src/app/tituladoform/interfaces/formTitulado/putForm';
import { TituladoResponse } from 'src/app/tituladoform/interfaces/formTitulado/tituladoResponse';
import { environment } from 'src/environments/environment';
import { AmbitoLaboral } from '../../interfaces/formTitulado/ambitoLaboral';
import { TipoContrato } from '../../interfaces/formTitulado/contratoTipo';
import { TiempoOferta } from '../../interfaces/formTitulado/tiempo_oferta-interface';
import { TiempoSinTrabajo } from '../../interfaces/formTitulado/tiempo_sin_trabajo-interface';

@Injectable({
  providedIn: 'root'
})
export class TituladoService {

  constructor(
    private http:HttpClient
  ) { }
  
  getComunas():Observable<Comuna[]>{
    return this.http.get<Comuna[]>(`${environment.hostDev}/v1/localidad/comuna/`)
  };

  getPaises():Observable<Pais[]>{
    return this.http.get<Pais[]>(`${environment.hostDev}/v1/localidad/pais/`)
  };

  getTituladoForm(formulario:FormPut):Observable<TituladoResponse>{
    var rut = formulario.rut.replace(/[.]/g,"");

    var partes = rut.split("-");
    rut=partes[0];
    var dv=partes[1];

    return this.http.post<TituladoResponse>(`${environment.hostDev}/formularios/v1/titulado/`, {"rut":rut,"dv":dv});
  }
  
  
  getInformeFormulario(rut:any){
    return this.http.get(`${environment.hostDev}/formularios/v1/formulario/enviar_informe/${rut}`)
  }

  putFormulario(formulario:any){
   
    return this.http.put<Formulario>(`${environment.hostDev}/formularios/v1/titulado/`,formulario);
  }

  getGenero():Observable<Genero[]>{
    return this.http.get<Genero[]>(`${environment.hostDev}/v1/dato_personal/genero/`)
  }
  getContratoTipo():Observable<TipoContrato[]>{
    return this.http.get<TipoContrato[]>(`${environment.hostDev}/formularios/v1/contrato-tipo/`)
  }
  getAmbitoLaboral():Observable<AmbitoLaboral[]>{
    return this.http.get<AmbitoLaboral[]>(`${environment.hostDev}/formularios/v1/ambito-laboral/`)
  }
  getTiempoOfertas():Observable<TiempoOferta[]>{
    return this.http.get<TiempoOferta[]>(`${environment.hostDev}/formularios/v1/tiempo-oferta/`)
  }
  getTiempoSinTrabajo():Observable<TiempoSinTrabajo[]>{
    return this.http.get<TiempoSinTrabajo[]>(`${environment.hostDev}/formularios/v1/tiempo-sin-trabajo/`)
  }

  
}
