import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError,  } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    var header = req.headers;
    //El token está en este archivo
    const token = environment.token;

    header = header.set('x-api-key', token);

    const reqClone = req.clone({
      headers:header

    });

    

    return next.handle( reqClone ).pipe(
      catchError(err => {
        return this.trabajarError(err);
      
      }),
    )
  }

  trabajarError(error:HttpErrorResponse){

    if(error.status == 400){ //(Bad Request)

      let mensaje='';

      if(error.error.mensaje){
        mensaje='<ul>';
        if(error.error.mensaje.constructor.name=='Array'){
            for (let i = 0; i < error.error.mensaje.length; i++) {
              mensaje+='<li>'+error.error.mensaje[i]+'</li>';
            }
        }else{
          mensaje+=error.error.mensaje
        }
        
        mensaje+='</ul>';

        return throwError(mensaje)
      
      //Esto es para los casos de los serializer
      }else if(error.error.constructor.name == "Object"){

        let claves = Object.keys(error.error); // claves = ["nombre", "color", "macho", "edad"]
        for(let i=0; i< claves.length; i++){
          let clave = claves[i];
          for (let j = 0; j < error.error[clave].length; j++) {
      
            mensaje+="<b>"+clave+"</b> : "+error.error[clave][j]+"<br>"
          }
          
        }
        return throwError(mensaje)
      }
    }

    return throwError('Interceptor: Error no abordado\n'+error.message)
  }

}
