import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';


const routes :Routes=[
  {
    path:'',
    loadChildren: ()=>import('./index/index.module').then(m=>m.IndexModule),
  },
  {
    path:'titulado',
    loadChildren: ()=>import('./tituladoform/tituladoform.module').then(m=>m.TituladoModule)
  },
  {
    path:'captura',
    loadChildren: ()=>import('./crm/crm.module').then(m=>m.CRMModule)
  },
  {
    path:'formacion_continua',
    loadChildren: ()=>import('./formacion-continua/formacion-continua.module').then(m=>m.FormacionContinuaModule)
  },
  {
    path:'ase',
    loadChildren: ()=>import('./formulario-ase/formulario-ase.module').then(m=>m.FormularioASEModule)
  },
  {
    path:'**',
    loadChildren: ()=>import('./cuestionario/cuestionario.module').then(m=>m.CuestionarioModule)
  }
]





@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports:[
    RouterModule
  ]
})
export class AppRoutingModule { }
