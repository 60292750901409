import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';


//components
import { TituladoComponent } from "./componentes/titulado/titulado.component";
import { TituladoRoutingModule } from "./titulado-routing.module";




@NgModule({
    declarations:[
        TituladoComponent,
        
    
    ],
    exports:[
        TituladoComponent,
    ],
    imports:[
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        TituladoRoutingModule,
    ]
})
export class TituladoModule{

}